import { ReactComponent as Trash } from '../assets/trash-can.svg';
import { useState } from 'react';

export default function ReservationCard({
  status,
  startDate,
  endDate,
  equipmentName,
  renterName,
  setApproveModal,
  setDenyModal,
  setCancelModal,
  setDeleteModal,
  setArchiveRes,
  resId,
  reservation,
  setSelectedReservation,
  setShowReservationInfoModal,
}) {
  const options = {
    day: 'numeric',
    year: 'numeric',
    month: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const STATUS_COLORS = {
    pending: 'bg-[#fcf39f] text-yellow-800',
    approved: 'bg-green-100 text-green-800',
    canceled: 'bg-red-100 text-red-800',
    filled: 'bg-blue-100 text-blue-800',
  };

  return (
    <div
      className="max-w-md w-full bg-white shadow rounded-lg overflow-hidden mb-4 flex flex-col h-full cursor-pointer"
      onClick={() => {
        setSelectedReservation(reservation);
        setShowReservationInfoModal(true);
      }}
    >
      <div className="p-5 relative flex-grow">
        <div className="flex justify-between items-start">
          <span
            className={`inline-block px-3 py-1 text-sm font-semibold rounded-full ${STATUS_COLORS[status]}`}
          >
            {status.charAt(0).toUpperCase() + status.slice(1)}
          </span>

          {(status === 'filled' || status === 'canceled') &&
            reservation.archived === false && (
              <button
                className="text-gray-400 hover:text-red-600 focus:outline-none"
                aria-label="Delete Reservation"
                onClick={event => {
                  event.stopPropagation();
                  setDeleteModal(true);
                  setArchiveRes(resId);
                }}
              >
                <Trash width={25} height={25} />
              </button>
            )}
        </div>

        <h2 className="mt-2 text-xl font-bold text-gray-800">
          {equipmentName}
        </h2>

        <p className="mt-1 text-gray-600">
          Rented by:{' '}
          {renterName === 'undefined undefined'
            ? 'UNREGISTERED USER'
            : renterName}
        </p>

        <div className="mt-4">
          <div className="flex justify-between text-gray-700">
            <span>Start Date:</span>
            <span>{new Date(startDate).toLocaleString('en-US', options)}</span>
          </div>
          <div className="flex justify-between text-gray-700">
            <span>End Date:</span>
            <span>{new Date(endDate).toLocaleString('en-US', options)}</span>
          </div>
        </div>
      </div>
      <div className="mt-auto flex space-x-2 p-4">
        {status === 'pending' && (
          <>
            <button
              onClick={event => {
                event.stopPropagation();
                setApproveModal(true);
                setSelectedReservation(reservation);
              }}
              className="flex-1 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 focus:outline-none"
            >
              Approve
            </button>
            <button
              onClick={event => {
                event.stopPropagation();
                setDenyModal(true);
                setSelectedReservation(reservation);
              }}
              className="flex-1 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none"
            >
              Deny
            </button>
          </>
        )}
        {status === 'approved' && (
          <button
            onClick={event => {
              event.stopPropagation();
              setCancelModal(true);
              setSelectedReservation(reservation);
            }}
            className="w-full px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600 focus:outline-none"
          >
            Cancel
          </button>
        )}
      </div>
    </div>
  );
}
