import { useEffect, useId, useRef, useState } from 'react';
// import { format, isValid, parse } from 'date-fns';
import { DayPicker } from 'react-day-picker';

import './calendar-picker.css';
export function CalendarDialog({ value, onChange }) {
  const dialogRef = useRef(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedRange, setSelectedRange] = useState(value);

  useEffect(() => {
    if (value) setSelectedRange(value); // Sync with parent
  }, [value]);

  useEffect(() => {
    if (dialogRef.current) {
      if (isDialogOpen) {
        dialogRef.current.showModal();
      } else {
        dialogRef.current.close();
      }
    }
  }, [isDialogOpen]);

  const toggleDialog = () => {
    setIsDialogOpen(prev => !prev);
  };

  const handleDayPickerSelect = range => {
    if (!range) return { to: null, from: null };
    setSelectedRange(range);
    onChange(range);
  };

  const handleDialogClick = e => {
    e.stopPropagation();
  };

  return (
    <div>
      <button className="border-2 p-1 rounded" onClick={toggleDialog}>
        📆 Select Date Range
      </button>
      <dialog
        ref={dialogRef}
        onClick={toggleDialog}
        className="border-2 rounded-lg"
      >
        <div
          className="p-4 flex justify-center items-center flex-col"
          onClick={handleDialogClick}
        >
          <DayPicker
            mode="range"
            numberOfMonths={2}
            selected={selectedRange}
            onSelect={handleDayPickerSelect}
            disabled={{ before: new Date() }}
          />
          <button
            className="mb-4 p-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            onClick={() => setIsDialogOpen(false)}
          >
            Confirm Dates
          </button>
        </div>
      </dialog>
    </div>
  );
}
